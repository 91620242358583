import axios, { AxiosError, AxiosResponse } from 'axios';
import { BASE_URL } from 'src/shared/constants/constants';

export type TelegramLogData = {
  user: string,
  url: string,
  message: string,
  status: number,
  data: any,
}

class TelegramLogService {
  static async log(errorData: TelegramLogData) {
    try {
      const response: AxiosResponse<boolean> = await axios.post(`${BASE_URL}/api/telegram/logs`, { message: JSON.stringify(errorData) });
      const { data } = response;

      if (data === undefined) throw response;
      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return error.response.data.detail;
      return error;
    }
  }
}

export default TelegramLogService;
