import { AxiosError, AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { TradingSettings, PartialTradingSettings, CloseOrdersIfFositionZeroChangeParams } from './types';

export const fetchTradeSettings = createAsyncThunk(
  'tradeSettings/fetchTradeSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TradingSettings> = await $api.get(ROUTE_PATH.users.tradeSettings);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchChangeTradeSettings = createAsyncThunk(
  'tradeSettings/fetchChangeTradeSettings',
  async (params: PartialTradingSettings | CloseOrdersIfFositionZeroChangeParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse = await $api.put(ROUTE_PATH.users.tradeSettings, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);
