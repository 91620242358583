import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store/store';

export const selectReferrals = (state: RootState) => state.referrals;

export const selectReferralsBonusesFilters = createSelector(
  selectReferrals,
  (referrals) => referrals.filters,
);

export const selectReferralsBonuses = createSelector(
  selectReferrals,
  (referrals) => referrals.referralsBonuses,
);

export const selectReferralStatus = createSelector(
  selectReferrals,
  (referrals) => referrals.status,
);

export const selectReferralsStatisticsStatus = createSelector(
  selectReferrals,
  (referrals) => referrals.referralsStatisticsStatus,
);

export const selectReferralsStatistics = createSelector(
  selectReferrals,
  (referrals) => referrals.referralsStatistics,
);

export const selectReferralsBonusesStatistics = createSelector(
  selectReferrals,
  (referrals) => referrals.referralsBonusesStatistics,
);

export const selectStatusBonusesWithddraw = createSelector(
  selectReferrals,
  (referrals) => referrals.statusBonusesWithdraw,
);
