import { createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { IExchangeSlice } from './types';
import { fetchExchanges } from './thunks';

const initialState: IExchangeSlice = {
  exchanges: null,
  allExchanges: null,
  status: EStatus.loading,
};

export const exchangesSlice = createSlice({
  name: 'exchanges',
  initialState,
  reducers: {
    removeExchanges: (state) => {
      state.exchanges = null;
      state.allExchanges = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchanges.pending, (state) => {
        state.status = EStatus.loading;
      })
      .addCase(fetchExchanges.fulfilled, (state, action) => {
        state.status = EStatus.success;
        state.allExchanges = action.payload;
        state.exchanges = action.payload.filter((exchange) => exchange.is_active).slice().sort((a, b) => a.id - b.id);
      })
      .addCase(fetchExchanges.rejected, (state) => {
        state.status = EStatus.rejected;
        state.exchanges = null;
      });
  },
});

export const { removeExchanges } = exchangesSlice.actions;

export const exchangesReducer = exchangesSlice.reducer;
