import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { AxiosError, AxiosResponse } from 'axios';
import { DetailError, TradingType } from 'src/shared/types/global-types';
import { RebateHistoryData, RebatesBonusesBalance, RebateStatistics } from './types';

export type RebateStatisticsParams = {
  from_date: string
  to_date: string
  sub_account_id: number
  exchange_id: number
  trading_type: TradingType
}

export type RebateHistoryParams = {
  from_date: string
  to_date: string
  page: number
  limit: number
  sub_account_id?: number
  exchange_id?: number
}

export type RebatesBonensWithdrawParams = {
  exchange_id: number;
  quantity: string;
}

export const fetchRebateHistory = createAsyncThunk(
  'rebate/fetchRebateHistory',
  async (params: RebateHistoryParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<RebateHistoryData> = await $api.get(ROUTE_PATH.subAccounts.rebates, {
        params,
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchRebateStatistics = createAsyncThunk(
  'rebate/fetchRebateStatistics',
  async (params: RebateStatisticsParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<RebateStatistics> = await $api.get(ROUTE_PATH.subAccounts.rebatesStatistics, {
        params,
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchRebatesBonensBalance = createAsyncThunk(
  'rebate/fetchRebatesBonensBalance',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<RebatesBonusesBalance> = await $api.get(ROUTE_PATH.subAccounts.rebatesBonusesBalance);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchRebatesBonusesWithdraw = createAsyncThunk(
  'rebate/fetchRebatesBonensWithdraw',
  async (params: RebatesBonensWithdrawParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<boolean | string> = await $api.post(ROUTE_PATH.subAccounts.rebatesBonusesWithdraw, params);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);
