import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'src/app/store/slices/user/selectors';
import { setThemeMode } from 'src/app/store/slices/user/slice';
import { ChangeTheme, fetchChangeTheme } from 'src/app/store/slices/user/thunks';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { useAppDispatch } from 'src/app/store/store';
import useAlert from './use-alert';

export const useTheme = () => {
  const { setAlertMessage } = useAlert();
  const dispatch = useAppDispatch();
  const defaultTheme = localStorage.getItem('appTheme') || EThemeMode.DARK;
  const theme = useSelector(selectTheme) || defaultTheme;

  const fetchSetTheme = async (mode: EThemeMode) => {
    try {
      const params: ChangeTheme = {
        theme: mode,
      };
      dispatch(setThemeMode(mode));
      const { payload } = await dispatch(fetchChangeTheme(params));

      if (payload === 'TOO_MANY_REQUESTS') {
        setAlertMessage('too many attempts 15', 'warning');
      }
    } catch (error) {
      console.debug(error);
    }
  };

  useLayoutEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);

    localStorage.setItem('appTheme', theme);
  }, [theme]);

  return {
    theme, setTheme: fetchSetTheme,
  };
};
