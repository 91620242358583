import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectExchanges } from 'src/app/store/slices/exchanges/selectors';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { EStatus, Nullable } from 'src/shared/types/global-types';

const useExchange = () => {
  const { allExchanges: exchanges, status } = useSelector(selectExchanges);
  const subAccounts = useSelector(selectSubAccounts);

  const unknownString = 'Unknown';

  const getExchangeImageById = (exchangeId: Nullable<number>) => {
    if (!exchangeId) return '';
    const findExchangeImage = exchanges?.find((exchange) => exchange.id === exchangeId);
    return findExchangeImage ? findExchangeImage.image : '';
  };

  const getExchangeNameById = (exchangeId: Nullable<number>): string => {
    if (!exchangeId) return unknownString;
    const findExchangeName = exchanges?.find((exchange) => exchange.id === exchangeId);
    return findExchangeName ? findExchangeName.name : unknownString;
  };

  const getExchangeIdByExchangeName = (name: string): Nullable<number> => {
    const findExchange = exchanges?.find((item) => item.name.toLowerCase() === name.toLowerCase());
    return findExchange ? findExchange.id : null;
  };

  const availableExchanges = useMemo(() => {
    if (!exchanges || !subAccounts) return null;
    const exchangeIds = new Set(subAccounts.map((account) => account.exchange_id));
    return exchanges.filter((exchange) => exchangeIds.has(exchange.id));
  }, [exchanges, subAccounts]);

  const isExchangesLoading = useMemo(() => status === EStatus.loading, [status]);
  const isExchangesRejected = useMemo(() => status === EStatus.rejected, [status]);
  return {
    getExchangeImageById,
    getExchangeNameById,
    getExchangeIdByExchangeName,
    availableExchanges,
    isExchangesLoading,
    isExchangesRejected,
  };
};

export default useExchange;
