import { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getActivePageByLocation, join, scrollToTop } from 'src/shared/libs/helpers/helper.lib';

import SupportService from 'src/services/support.service';

import { ReactComponent as ProfileIconSvg } from 'src/shared/assets/images/aside/profile.svg';
import { ReactComponent as SupportIconSvg } from 'src/shared/assets/images/aside/support.svg';
import { ReactComponent as TradingIconSvg } from 'src/shared/assets/images/aside/trading.svg';
import { ReactComponent as AccountsIconSvg } from 'src/shared/assets/images/aside/accounts.svg';
import { ReactComponent as SettingsIconSvg } from 'src/shared/assets/images/aside/settings.svg';
import { ReactComponent as LeveragesIconSvg } from 'src/shared/assets/images/aside/leverages.svg';
import { ReactComponent as TransfersIconSvg } from 'src/shared/assets/images/aside/transfers.svg';
import { ReactComponent as BonusIconSvg } from 'src/shared/assets/images/aside/bonus.svg';
import { ReactComponent as ReferralIconSvg } from 'src/shared/assets/images/aside/referral.svg';

import './aside-menu.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsActiveReferralProgram } from 'src/app/store/slices/user/selectors';

interface IAsideMenuProps {
  close?: () => void
}

const AsideMenu = memo(({ close }: IAsideMenuProps) => {
  const location = useLocation();
  const activePage = getActivePageByLocation(location);
  const isActiveREferralProgram = useSelector(selectIsActiveReferralProgram);

  const { t } = useTranslation();

  const handleSupportTelegram = async () => {
    try {
      const response = await SupportService.telegram();
      const { url } = response;

      if (close) close();

      if (url) window.open(url, '_blank');
    } catch (error) {
      console.debug(error);
    }
  };

  const handleCloseAdaptiveMenu = () => {
    if (close) close();
    scrollToTop();
  };

  const activeProfilePages = ['profile', 'security', 'api-keys', 'documents', 'verification'];

  return (
    <div className="aside-menu">
      <nav>
        <Link
          onClick={handleCloseAdaptiveMenu}
          to="/trading"
          className={join('menu-item', activePage === 'trading' && 'menu-item_active')}
        >
          <TradingIconSvg />
          <span>{t('trading')}</span>
        </Link>

        <Link
          onClick={handleCloseAdaptiveMenu}
          to="/sub-accounts"
          className={join('menu-item', (activePage === 'sub-accounts') && 'menu-item_active')}
        >
          <AccountsIconSvg />
          <span>{t('accounts')}</span>
        </Link>

        <Link
          onClick={handleCloseAdaptiveMenu}
          to="/transfers"
          className={join('menu-item', activePage === 'transfers' && 'menu-item_active')}
        >
          <TransfersIconSvg />
          <span>{t('transfers')}</span>
        </Link>

        <Link
          onClick={handleCloseAdaptiveMenu}
          to="/leverages"
          className={join('menu-item', activePage === 'leverages' && 'menu-item_active')}
        >
          <LeveragesIconSvg />
          <span>{t('leverages')}</span>
        </Link>

        <Link
          onClick={handleCloseAdaptiveMenu}
          to="/profile/bonuses"
          className={join('menu-item', activePage === 'bonuses' && 'menu-item_active')}
        >
          <BonusIconSvg />
          <span>{t('bunuses')}</span>
        </Link>

        <Link
          onClick={handleCloseAdaptiveMenu}
          to="/settings"
          className={join('menu-item', activePage === 'settings' && 'menu-item_active')}
        >
          <SettingsIconSvg />
          <span>{t('settings')}</span>
        </Link>

        <Link
          onClick={handleCloseAdaptiveMenu}
          to="/profile"
          className={join('menu-item', activeProfilePages.includes(activePage) && 'menu-item_active')}
        >
          <ProfileIconSvg />
          <span>{t('profile')}</span>
        </Link>

        {isActiveREferralProgram && (
          <Link
            onClick={handleCloseAdaptiveMenu}
            to="/referrals"
            className={join('menu-item', activePage === 'referrals' && 'menu-item_active')}
          >
            <ReferralIconSvg />
            <span>{t('referrals')}</span>
          </Link>
        )}

        <button
          onClick={handleSupportTelegram}
          className={join('menu-item', activePage === 'support' && 'menu-item_active')}
        >
          <SupportIconSvg />
          <span>{t('support')}</span>
        </button>
      </nav>
    </div>
  );
});

export default AsideMenu;
