import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { DiaryLayout, MainLayout } from 'src/layouts';
import { useAuth } from 'src/shared/libs/hooks/use-auth';
import { useSelector } from 'react-redux';
import { selectIsActiveReferralProgram } from '../store/slices/user/selectors';

export const PrivateRouter = () => {
  const { isAuth, isWelcomePage } = useAuth();
  const { pathname } = useLocation();
  const isActiveReferralProgram = useSelector(selectIsActiveReferralProgram);

  if (isAuth) {
    if (pathname === '/referrals' && !isActiveReferralProgram) return <Navigate to="/profile" />;
    if (isWelcomePage) return <Navigate to="/welcome" />;

    const Layout = pathname.includes('trading-journal') ? DiaryLayout : MainLayout;

    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
  return <Navigate to="auth/login" />;
};
