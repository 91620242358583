/* eslint-disable indent */
import i18next from 'i18next';
import { TRADING_TYPES } from 'src/shared/constants/constants';
import { Nullable, TradingType } from 'src/shared/types/global-types';
import { FilterOption, PositionsSummary } from '../../model/types';

export const getDealText = (count: number): string => {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return `${count} ${i18next.t('trades_selected')}`;
  }
  if (lastDigit === 1) {
    return `${count} ${i18next.t('one_trade_selected')}`;
  } if (lastDigit >= 2 && lastDigit <= 4) {
    return `${count} ${i18next.t('selected_trades')}`;
  }
  return `${count} ${i18next.t('trades_selected')}`;
};

export const getTradingTypeName = (type: TradingType): string => {
  const result = TRADING_TYPES.find((item) => item.type === type);
  return result?.type || 'unknown';
};

export const getFiltersLabelOfValueString = (data: FilterOption[], value: [number | null, number | null], type: 'pnl_percent' | 'duration' | 'leverage' | 'profit_usdt' | 'quantity_usdt') => {
  const result = data.find((item) => item.value.length === value.length && item.value.every((val, index) => val === value[index]));
  if (result?.label) return i18next.t(result.label);

  if (type === 'pnl_percent') {
    if (value[0] && !value[1]) {
      return `${i18next.t('more')}  ${value[0]}%`;
    } if (!value[0] && value[1]) {
      return `${i18next.t('less')}  ${value[1]}%`;
    } if (value[0] && value[1]) {
      return `${i18next.t('from').toLowerCase()} ${value[0]}% ${i18next.t('to').toLowerCase()} ${value[1]}%`;
    }
  }
  if (type === 'duration') {
    if (value[0] && !value[1]) {
      return `${i18next.t('more')}  ${value[0]} ${i18next.t('min.')}`;
    } if (!value[0] && value[1]) {
      return `${i18next.t('from').toLowerCase()}  0 ${i18next.t('to').toLowerCase()}  ${value[1]}${value[1] ? ` ${i18next.t('min.')}` : `0 ${i18next.t('min.')}`}`;
    } if (value[0] && value[1]) {
      return `${i18next.t('from').toLowerCase()} ${value[0]}${value[0] ? '' : `0 ${i18next.t('min.')}`} ${i18next.t('to').toLowerCase()} ${value[1]}${value[1] ? ` ${i18next.t('min.')}` : `0 ${i18next.t('min.')}`}`;
    } if (!value[0] && !value[1]) {
      return i18next.t('open');
    }
  }
  if (type === 'leverage') {
    if (value[0] && !value[1]) {
      return `${i18next.t('more')}  x${value[0]}`;
    }

    return `x${value[0] || '0'} - x${value[1]}${value[1] ? '' : '0'}`;
  }
  if (type === 'profit_usdt') {
    if (value[0] && !value[1]) {
      return `${i18next.t('more')}  ${value[0]}$`;
    } if (!value[0] && value[1]) {
      return `${i18next.t('less')}  ${value[1]}$`;
    } if (value[0] && value[1]) {
      return `${i18next.t('from').toLowerCase()} ${value[0]}${value[0] ? '$' : '0$'} ${i18next.t('to').toLowerCase()} ${value[1]}${value[1] ? '$' : '0$'}`;
    }
  }
  if (type === 'quantity_usdt') {
    if (value[0] && !value[1]) {
      return `${i18next.t('more')} ${value[0]}$`;
    }
    return `${i18next.t('from').toLowerCase()} ${value[0] ? `${value[0]}$` : '0$'} ${i18next.t('to').toLowerCase()} ${value[1]}${value[1] ? '$' : '0$'}`;
  }

  return '';
};

export const formatDuration = (seconds: number, preLine?: boolean): string => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const parts = [];

  if (days > 0) {
    parts.push(`${days}${i18next.t('d')},`);
  }
  if (hours > 0) {
    parts.push(`${hours}${i18next.t('h')}${preLine ? '' : ','}\n`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}${i18next.t('m')},`);
  }

  // Всегда добавляем секунды, даже если они равны 0
  parts.push(`${secs}${i18next.t('s')}`);

  return parts.join(' ');
};

export const onlyIntegers = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === '.' || event.key === ',') {
    event.preventDefault();
  }
};

export const geTickerFromSessionStorage = () => {
  try {
    const storedValue = sessionStorage.getItem('tickerParams');
    if (storedValue) {
      return JSON.parse(storedValue);
    }
  } catch (error) {
    console.debug(`Failed to get tickerParams from localStorage: ${error}`);
  }

  return null;
};

export const geTradesFiltersFromSessionStorage = () => {
  try {
    const storedValue = sessionStorage.getItem('tradesFilters');
    if (storedValue) {
      return JSON.parse(storedValue);
    }
  } catch (error) {
    console.debug(`Failed to get tickerParams from localStorage: ${error}`);
  }

  return null;
};

export const getComparableValue = (column: string, item: PositionsSummary) => {
  switch (column) {
    case 'instrument':
      return item.instrument.symbol;
    case 'sub_account_id':
      return item.sub_account_id;
    case 'exchange_id':
      return item.exchange_id;
    case 'side':
      return item.side;
    case 'entry_price':
    case 'exit_price':
    case 'pnl':
    case 'quantity_usdt':
    case 'quantity':
    case 'trade_max_quantity':
    case 'profit':
    case 'funding_fee':
    case 'cumulative_quantity':
    case 'commission':
    case 'max_loss':
    case 'max_loss_percent':
    case 'max_profit':
    case 'max_profit_percent':
      return parseFloat(item[column] || '0');
    case 'leverage':
      return item.leverage || 0;
    case 'duration':
      return item.duration;
    case 'entered_at':
      return item.entered_at instanceof Date ? item.entered_at.getTime() : new Date(item.entered_at).getTime() || 0;
    case 'exited_at':
      return item.exited_at instanceof Date ? item.exited_at.getTime() : (item.exited_at ? new Date(item.exited_at).getTime() : null);
    case 'pnl_percent':
      return item.pnl_percent;
    default:
      return null;
  }
};

export function hasNonNullPropertyExceptPage(obj: any) {
  return Object.entries(obj)
    .filter(([key]) => key !== 'page')
    .some(([key, value]) => value !== null);
}

export const isDateFilter = (filterValue: any, dateRange: [Nullable<Date>, Nullable<Date>]) => {
  const enteredAtFilter = new Date(filterValue.fromDate);
  const exitedAtFilter = new Date(filterValue.toDate);

  const enteredAt = new Date(dateRange[0] || '');

  if (Number.isNaN(enteredAt.getTime())) return false;

  return (
    enteredAt >= enteredAtFilter
    && enteredAt <= exitedAtFilter
  );
};

export const isDurationFilter = (position: PositionsSummary, filterValue: any) => {
  const startDurationMin = filterValue[0] || 0;
  const endDurationMax = filterValue[1] || 0;
  const durationMinut = +Number(position.duration / 60).toFixed();

  if (!startDurationMin && !endDurationMax && !durationMinut && !position.exited_at) {
    return true;
  }

  if (startDurationMin <= durationMinut && !position.exited_at) {
    return true;
  }

  if (startDurationMin && !endDurationMax) {
    if (durationMinut >= startDurationMin) return true;
  }

  if (!startDurationMin && endDurationMax) {
    if (durationMinut <= endDurationMax) return true;
  }

  if (startDurationMin && endDurationMax) {
    if (durationMinut >= startDurationMin && durationMinut <= endDurationMax) return true;
  }

  return false;
};

export const isPnlPercentFilter = (position: PositionsSummary, filterValue: any) => {
  // if (!tradeFilters.pnlPercent) return false;
  const pnlPercent = position.pnl_percent;

  const startPnl = filterValue[0] as number;
  const endPnl = filterValue[1] as number;

  if (startPnl && !endPnl) {
    if (pnlPercent >= startPnl) {
      return true;
    }
  }

  if (!startPnl && endPnl) {
    if (pnlPercent <= endPnl) {
      return true;
    }
  }

  if (pnlPercent >= startPnl && pnlPercent <= endPnl) return true;

  return false;
};

export const isLeverageFilter = (position: PositionsSummary, filterValue: any) => {
  const { leverage } = position;
  if (!leverage) return false;

  const startLeverage = filterValue[0] as number;
  const endLeverage = filterValue[1] as number;

  if (startLeverage && !endLeverage) {
    if (leverage >= startLeverage) {
      return true;
    }
  }

  if (!startLeverage && endLeverage) {
    if (leverage <= endLeverage) {
      return true;
    }
  }

  if (leverage >= startLeverage && leverage <= endLeverage) return true;
  // console.log(`start - ${startLeverage}; end - $${endLeverage}; position - ${position.leverage}`);
  // console.log(position);
  return false;
};

export const isQuantityUsdtFilter = (position: PositionsSummary, filterValue: any): boolean => {
  const { quantity_usdt: quantityUsdt } = position;
  if (!quantityUsdt) return false;

  const [startQuantityUsdt, endQuantityUsdt] = filterValue.map(Number);

  return (
    (startQuantityUsdt ? +quantityUsdt >= startQuantityUsdt : true)
    && (endQuantityUsdt ? +quantityUsdt <= endQuantityUsdt : true)
  );
};

export const isQuantityFilter = (position: PositionsSummary, filterValue: any): boolean => {
  const { quantity } = position;
  if (!quantity) return false;

  const [startQuantity, endQuantity] = filterValue.map(Number);

  return (
    (startQuantity ? +quantity >= startQuantity : true)
    && (endQuantity ? +quantity <= endQuantity : true)
  );
};
